<template>
  <div class="filter-select-tree">
    <div class="filter">
      <input type="text" ref="input" v-model="filterText" :placeholder="$t('tree_search')">
      <div v-if="filterText.length > 0" class="clear" @click="clearFilter"></div>
      <div v-else class="search-icon"></div>
    </div>
    <div class="container-select-tree">
      <div class="select-tree">
        <el-tree
          ref="selectTree" 
          :data="data" 
          :props="defaultProps"
          node-key="id"
          :empty-text="$t('tree_no_data')" 
          :expand-on-click-node="false"
          :show-checkbox="isMultipleSelect"
          :default-expanded-keys="expandKeys"
          :filter-node-method="filterNode"
          @check="handleCheck"
          @node-click="handleNodeClick"
          @node-expand="handleNodeExpand"
          @node-collapse="handleNodeCollapse">
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <div v-if="!isMultipleSelect && ((isGroupSelect && data.children) || !data.children)" class="wrap-div">
              <div class="circle" :class="{ active: data.id === selectedData.id }"></div>
            </div>
            <div v-if="data.children" class="wrap-div">
              <img src="@/assets/icons/Group-yellow.svg" alt="">
            </div>
            <span :class="{'has-child': data.children}">{{ node.label }}</span>
          </div>
        </el-tree>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSelectTree',
  props: {
    value: {
      required: true,
      type: [Object, Array]
    },
    data: {
      type: Array
    },
    groupList: {
      type: Array
    },
    isGroupSelect: {
      type: Boolean,
      default: true
    },
    isMultipleSelect: {
      type: Boolean,
      default: false
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'label'
        }
      }
    }
  },
  data() {
    return {
      filterText: '',
      // defaultProps: {
      //   children: 'children',
      //   label: 'label'
      // },
      expandKeys: [],
      clicks: 0,
      clickTimer: null,
    }
  },
  computed: {
    selectedData: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  watch: {
    'data.length'() {
      if (this.data?.length > 0) {
        this.expandKeys = [this.data[0].id]
      }
    },
    filterText(val) {
      this.$refs.selectTree.filter(val)
    },
    selectedData: {
      handler() {
        this.$nextTick(() => {
          this.initSelectTree()
        })
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSelectTree()
    }) 

    if (this.data && this.data.length > 0)
      this.expandKeys = [this.data[0].id]
  },
  methods: {
    initSelectTree() {
      try {
        if (this.data && this.data.length > 0)
          this.expandKeys = [this.data[0].id]

        if (!Array.isArray(this.selectedData)) return
        let checkedNodeIds = this.selectedData.map(data => {
          return data.id
        })
        if (this.$refs.selectTree)
          this.$refs.selectTree.setCheckedKeys(checkedNodeIds, true)
      } catch (error) {
        console.log('initSelectTree error ===> ',error)
      }
    },
    clearFilter() {
      this.filterText = ''
    },
    filterNode(val, data) {
      let checkedNodes = this.$refs.selectTree.getCheckedNodes()
      let checked = checkedNodes.findIndex(node => node.id === data.id) >= 0
      
      let filtered = true
      if (this.filterText !== '') {
        const filter = this.filterText.toLowerCase()
        filtered = data.label.toLowerCase().includes(filter)
        const groupName = this.groupList.find(g => g.id === data.groupId)?.label
        filtered = filtered || groupName?.toLowerCase().includes(filter)
      }
      return checked || filtered
    },
    nodeSingleClick(data, node) {
      if (!node.expanded) {
        this.expandKeys.push(data.id)
      }

      if (!this.isGroupSelect && data.children) return

      // 若點到已選取node，則取消該node
      if (data.id === this.selectedData.id && data.groupId === this.selectedData.groupId) {
        this.selectedData = {
          id: '',
          label: '',
          groupId: '',
          isHasChild: false,
        }
      } else {
        const obj = {
          id: data.id,
          label: data.label, // 目前先帶label(videoTitle + user.id)
          groupId: data.groupId,
          isHasChild: (data.children && data.children.length > 0) ? true : false,
        }
        this.selectedData = obj
      }
    },
    nodeExpand(data, node) {
      if (!node.expanded) {
        this.expandKeys.push(data.id)
      } else {
        // 要先把子層都收合，父層才可以收合
        node.childNodes.forEach(n => {
          if (n.expanded) {
            this.nodeCollapse(n)
          }
        })
        this.expandKeys = this.expandKeys.filter(v => v !== node.data.id)
        node.expanded = false
      }
    },
    nodeCollapse(node) {
      node.childNodes.forEach(n => {
        if (n.expanded) {
          this.nodeCollapse(n)
          this.expandKeys = this.expandKeys.filter(v => v !== n.data.id)
          n.expanded = false
        }
      })
      this.expandKeys = this.expandKeys.filter(v => v !== node.data.id)
      node.expanded = false
    },
    handleCheck() {
      const checkedNodes = this.$refs.selectTree.getCheckedNodes()
      this.selectedData = checkedNodes.filter(node => !node.children)
    },
    handleNodeClick(data, node) {
      this.clicks++
      if (this.clicks === 1) {
        this.clickTimer = setTimeout(() => {
          // single click: 點選設定設備
          this.nodeSingleClick(data, node)
          this.clicks = 0
        }, 300)
      } else {
        clearTimeout(this.clickTimer)
        this.clickTimer = null
        // double click：展開該群組
        if (data.children) {
          this.nodeExpand(data, node)
        }
        this.clicks = 0
      }
    },
    handleNodeExpand(data) {
      const index = this.expandKeys.indexOf(data.id)
      if (index === -1) {
        this.expandKeys.push(data.id)
      }
    },
    handleNodeCollapse(data) {
      const index = this.expandKeys.indexOf(data.id)
      if (index > -1) {
        this.expandKeys.splice(index, 1)
      }
    } 
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.filter-select-tree {
  width: 100%;
  color: #ffffff;
  background: #151B35;
  border: 1px solid #4A5C78;
  border-radius: 4px;
  z-index: 2;
  transition: all 200ms ease-in-out;
}

.container-select-tree {
  padding-right: 2px;
}

.select-tree {
  margin: 0;
  padding: 6px 4px 12px;
  max-height: calc(100vh - 300px);
  overflow: overlay;
}

.filter {
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 16px;
  border-bottom: 1px solid #4A5C78;
}

.filter input {
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: px2rem(18);
  font-weight: 300;
  border-radius: 4px;
  height: 36px;
  padding-right: 24px;
  margin-right: 8px;
  background: #151B35;
}

.clear {
  position: absolute;
  right: 20px;
  top: 18px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-image: url('../../assets/icons/clear.svg');
  background-size: 12px;
  &:hover {
    background-image: url('../../assets/icons/clear-yellow.svg');
  }
}

.search-icon {
  width: 22px;
  height: 22px;
  background-image: url('../../assets/icons/feather-search-gray.svg');
  cursor: pointer;
  &:hover {
    background-image: url('../../assets/icons/feather-search-hover.svg');
  }
}

/* el-tree */
::v-deep .el-tree {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: #151B35;
  overflow: overlay;
}

/* 設定點擊才不會閃白底 */
::v-deep .el-tree .el-tree-node .el-tree-node__content {
  background: #151b35;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: 6px 0;
}

::v-deep .el-tree-node__label {
  font-size: px2rem(18);
  font-weight: 300;
  line-height: 40px;
  color: #ffffff;
}

/* 調整三角形大小 */
::v-deep .el-tree-node__expand-icon {
  font-size: 20px;
}

/* 調整三角形顏色需排除 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) {
  color: #ffffff;
}

/* 父層文字顏色 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) ~ .el-tree-node__label {
  color: #FFC600;
}

::v-deep .el-checkbox__inner {
  border-color: #191919;
}

::v-deep .el-tree-node__content:hover {
  color: #ffffff;
  background: #384C83;
}

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  color: #ffffff;
  background: #384C83;
}

.custom-tree-node {
  display: flex;
  align-items: flex-start;
}

.custom-tree-node img {
  margin-right: 8px;
}

.custom-tree-node span {
  line-height: 32px;
}

.wrap-div {
  height: 32px;
  display: flex;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  margin-right: 10px;
  position: relative;
  flex-shrink: 0;
}

.active {
  border-color: #FFC600;
}

.active::before {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FFC600;
  left: 4px;
  top: 4px;
}

.has-child {
  color: #FFC600;
  font-weight: 400;
}

::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
  // border-color: #ffffff;
  border: 2px solid #ffffff;
  background-color: #151B35;
  outline: none;
  transition: none;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, 
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #FFC600;
  border-color: #FFC600;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  background: #151B35;
  top: 6px;
  height: 4px;
  left: -1px;
  right: -1px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  border: 2px solid #151B35;
  border-left: 0;
  border-top: 0;
  height: 9px;
  left: 5px;
  top: 1px;
  width: 5px;
}  
</style>