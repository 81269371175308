<template>
  <div class="wrap-tree-select" v-click-outside="onClickOutside">
    <div class="title">{{ title }}</div>
    <div class="tree-select" :class="{ active: optionShow }">
      <div class="selected-label" @click="handleClick">
        <template v-if="isMultipleSelect">
          <span v-if="selectedValue.length === 0" class="placeholder">{{ $t('all') }}</span>
          <span v-else>{{ $t('selected') }}({{ selectedValue.length }})</span>
        </template>
        <template v-else>
          <span v-if="!selectedValue || !selectedValue.label" class="placeholder">{{ $t('all') }}</span>
          <span v-else>{{ selectedValue.label }}</span>
        </template>
        <img :class="{ 'arrow-up': optionShow }" src="@/assets/icons/Drop.svg" alt="">
      </div>
      <transition name="fade">
        <FilterSelectTree 
          class="filter-options"
          :style="styleOptionWidth"
          v-show="optionShow"
          v-model="selectedValue" 
          :data="data" 
          :groupList="groupList"  
          :isGroupSelect="isGroupSelect"
          :isMultipleSelect="isMultipleSelect"
          :defaultProps="defaultProps"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import FilterSelectTree from './FilterSelectTree.vue'

export default {
  name: 'TreeSelect',
  components: { FilterSelectTree },
  props: ['value', 'data', 'groupList', 'title', 'isGroupSelect', 'isMultipleSelect', 'defaultProps', 'optionWidth'],
  data() {
    return {
      optionShow: false,
      filterText: '',
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.value
        // return this.value ? this.value : {
        //   id: '',
        //   name: '',
        //   groupId: '',
        //   isHasChild: false,
        // }
      },
      set(val) {
        this.$emit('input', val)
        if (!this.isMultipleSelect) {
          this.optionShow = false
        }
      }
    },
    styleOptionWidth() {
      return {
        width: this.optionWidth ? this.optionWidth : '100%'
      }
    }
  },
  watch: {
    // optionShow() {
    //   if (this.optionShow) {
    //     this.$nextTick(() => {
    //       this.$refs.input.focus()
    //     })
    //   }
    // },
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    handleClick() {
      this.optionShow = !this.optionShow
    },
    onClickOutside() {
      this.optionShow = false
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}


.title {
  color: #ffffff;
  font-size: px2rem(18);
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 4px;
}

.tree-select {
  width: 100%;
  position: relative;
  font-size: px2rem(18);
}

.selected-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #ffffff;
  color: #191919;
  border-radius: 8px;
  padding: 0 12px;
  font-weight: 300;
  line-height: 48px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    @include filter_191919;
    transition: all 200ms ease-in-out;

    &.arrow-up {
      transform: rotate(180deg);
    }
  }
}

.filter-options {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
}

.placeholder {
  color: #9D9D9D;
}
</style>